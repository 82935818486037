export const locKeys = {
  checkIn: "datePicker.fromDate.label",
  checkOut: "datePicker.toDate.label",
  submitButton: "wizard.submit.label",
  submitButtonWithHotelsAndFlights: "wizard.submit.label.searchHotelsAndFlights",
  submitButtonWithHotelsAndCars: "wizard.submit.label.searchHotelsAndCars",
  submitButtonWithHotelsFlightsAndCars: "wizard.submit.label.searchHotelsFlightsAndCars",
  getAvailability: "wizard.submit.label.getAvailability",
  getPrices: "wizard.submit.label.getPrices",
  getDeals: "wizard.submit.label.getDeals",
  roomsGuests: "wizard.roomPicker.roomsGuests",
  dialogTitle: "wizard.roomPicker.title",
  cancelButton: "wizard.roomPicker.closeButton.a11yLabel",
  guests: "wizard.roomPicker.title",
  invalidFromDate: "wizard.datePicker.warning.invalidFromDate.text",
  invalidToDate: "wizard.datePicker.warning.invalidToDate.text",
  invalidDateRangeEndBeforeStart: "wizard.datePicker.warning.endDateBeforeStartDate.text",
  invalidDateRangeEndOverLimit: "wizard.datePicker.warning.dateRangeOverLimit",
  hotelDestinationFieldLabel: "wizard.hotel.destination.label",
  invalidDestination: "wizard.destination.warning.invalidDestination.text",
  flightOriginFieldLabel: "wizard.origin.label",
  invalidOrigin: "wizard.origin.warning.invalidOrigin.text",
  addFlightLabel: "wizard.package.addFlight.label",
  directFlightsLabel: "wizard.package.directFlight.label",
  addCarLabel: "wizard.package.addCar.label",
  packageDisabledMessage: "wizard.package.disabled.message",
  airlineAgeRules: "wizard.airline.ageRules.label",
  airlineChildSeatingMessage: "wizard.airline.children.seatRules",
  airlineSeatingInSeat: "wizard.airline.children.seatOption.inSeat",
  airlineSeatingOnLap: "wizard.airline.children.seatOption.onLap",
  hotelLegalDisclaimer: "hotels.legal.disclaimer",
  stickyWizardTitle: "wizard.sticky.title",
  stickyWizardCallToAction: "wizard.sticky.cta.label",
  invalidNumberOfTravelersForPackage: "wizard.package.disabled.numberOfTravelers.message",
  invalidNumberOfTravelersError: "wizard.guestField.warning.invalidNumberOfTravelers.text",
  mobileLocToken: "wizard.mobile.label.hotel",
  needMultiplePlaces: "wizard.hotel.hotelPlusHotel.checkbox",
  dialogText: "wizard.hotel.hotelPlusHotel.oneAccommmodation.text",
  dialogTextWithoutCar: "wizard.hotel.hotelPlusHotel.dialog.message.withoutCar",
  dialogButton: "wizard.hotel.hotelPlusHotel.dialog.button",
  locToken: "wizard.mobile.label.hotel",
  typeaheadPlaceholder: "wizard.hotel.destination.placeholder.v2",
  filterTitle: "wizard.hotel.pwa.filter.title",
  filterAdvancedOptionsLink: "wizard.hotel.pwa.filter.label",
  filterBusinessFriendly: "wizard.hotel.pwa.filter.travel.type.business",
  filterFamilyFriendly: "wizard.hotel.pwa.filter.travel.type.family",
  filterFreeCancellation: "wizard.hotel.pwa.filter.payment.type.free.cancellation",
  filterBreakfastIncluded: "wizard.hotel.pwa.filter.amenities.free.breakfast",
  increaseChildren: "wizard.roomPicker.increaseChildren.text",
  increaseAdults: "wizard.roomPicker.increaseAdults.text",
  decreaseChildren: "wizard.roomPicker.decreaseChildren.text",
  decreaseAdults: "wizard.roomPicker.decreaseAdults.text",
  hotelStandaloneRates: "wizard.hotel.subTab.standalone",
  hotelPackageRates: "wizard.hotel.subTab.packageRates",
  newPropertySearch: "wizard.hotel.subTab.newPropertySearch",
  stickyWizardAffinityTitle: "wizard.sticky.affinity.title",
};
